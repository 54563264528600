import "./index.css";
import React from "react";
import NewProducts from "./components/NewProducts.jsx";
import Homepage from "./components/Homepage.jsx";
import Footer from "./components/Footer.jsx";
import Header from "./components/Header.jsx";
import { useLocation } from "react-router-dom";

function App() {
  const location = useLocation();
  return (
    <div>
      <Header />
      <body>
        <main>
        {location.pathname === "/newproducts" ? <NewProducts/> : <Homepage/>}<br/>
        </main>
      </body>
      <Footer />
    </div>
  );
}

export default App;
